const TIME_S = {
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 24 * 60 * 60,
};

const TIME_MS = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 60 * 1000,
  day: 24 * 60 * 60 * 1000,
};

function formatTimeString(time, options = {}) {
  if (!Number.isFinite(time)) {
    return 'calculating';
  }

  const TIME = options.inMs ? TIME_MS : TIME_S;

  let days = 0;
  if (options.withDays) {
    days = Math.floor(time / TIME.day);
  }

  let hours;
  if (options.withDays) {
    hours = Math.floor((time % TIME.day) / TIME.hour);
  } else {
    hours = Math.floor(time / TIME.hour);
  }

  const minutes = Math.floor((time % TIME.hour) / TIME.minute);
  const seconds = Math.floor((time % TIME.minute) / TIME.second);
  const milliSeconds = Math.round(time % TIME.second);

  let string = '';
  if (options.useColons) {
    const hhmmss = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');

    if (days > 0) string = `${days}d `;
    string += hhmmss;
    if (options.alwaysShowMs || (options.inMs && milliSeconds > 0)) {
      string += `.${milliSeconds.toString().padStart(3, '0')}`;
    }
  } else {
    const parts = [];

    if (days > 0) parts.push(`${days} d`);
    if (hours > 0) parts.push(`${hours} h`);
    if (minutes > 0) parts.push(`${minutes} min`);
    if (seconds > 0 || parts.length === 0) parts.push(`${seconds} s`);
    if (options.alwaysShowMs || (options.inMs && milliSeconds > 0)) parts.push(`${milliSeconds} ms`);

    string = parts.join(' ');
  }

  return string;
}

export default formatTimeString;
