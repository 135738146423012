import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import toParamsQueryString from 'plugins/utilities/to_params_query_string';

function ajax(method, url, data = undefined, options = {}) {
  let u = url;
  let d = data;
  const m = method.toUpperCase();

  const request = new XMLHttpRequest();

  if (m === 'GET' && d) {
    const params = toParamsQueryString(d);

    d = undefined;
    u = `${u}?${params}`;
  }

  request.open(m, u, true);

  if (
    !isVariableDefinedNotNull(options.noXRequestedWithHeader) ||
    !options.noXRequestedWithHeader
  ) {
    request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  }

  if (!isVariableDefinedNotNull(options.noAcceptHeader) || !options.noAcceptHeader) {
    request.setRequestHeader(
      'Accept',
      // eslint-disable-next-line max-len
      'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*; q=0.01',
    );
  }

  if (!isVariableDefinedNotNull(options.skipCsrfToken) || !options.skipCsrfToken) {
    const tokenElement = document.querySelector('meta[name="csrf-token"]');
    if (isVariableDefinedNotNull(tokenElement)) {
      const token = tokenElement.getAttribute('content');
      request.setRequestHeader('X-CSRF-Token', token);
    }
  }

  if (isVariableDefinedNotNull(options.withCredentials)) {
    request.withCredentials = options.withCredentials;
  }

  if (!isVariableDefinedNotNull(options.headers)) {
    if (m === 'POST' || m === 'PUT' || m === 'PATCH') {
      request.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
    }
  } else {
    Object.keys(options.headers).forEach(key => {
      request.setRequestHeader(key, options.headers[key]);
    });
  }

  if (isVariableDefinedNotNull(options.progress)) {
    request.addEventListener('progress', event => options.progress(request, event));
  }

  if (isVariableDefinedNotNull(options.success)) {
    request.addEventListener('load', event => {
      if (request.status >= 200 && request.status < 400) {
        return options.success(request, event);
      }

      if (isVariableDefinedNotNull(options.error)) {
        return options.error(request, event);
      }

      return true;
    });
  }

  if (isVariableDefinedNotNull(options.error)) {
    request.addEventListener('error', event => options.error(request, event));
  }

  if (isVariableDefinedNotNull(options.abort)) {
    request.addEventListener('abort', event => options.abort(request, event));
  }

  if (isVariableDefinedNotNull(options.complete)) {
    request.addEventListener('loadend', event => options.complete(request, event));
  }

  Object.keys(options).forEach(key => {
    if (['headers', 'progress', 'success', 'error', 'abort', 'complete'].indexOf(key) === -1) {
      request[key] = options[key];
    }
  });

  request.send(d);

  return request;
}

export default ajax;
